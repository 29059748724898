body {
    padding: 0;
    font-family: sans-serif;

    #root {
        padding: 0 2rem;

        .license-text {
            background-color: #eeeeee;
            border-top: 20px solid white;
            padding: 10px;
        }

        #main {
            display: flex;
            flex-direction: column;
            width: 15rem;
            padding-left: 40px;
            & > * {
                padding-top: 1rem;
            }
            .diff {
                display: flex;
                align-items: center;
            }
        }

        li {
            padding-top: 4px;
        }

        .between {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .copyright {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            font-size: 12px;
            text-align: center;
            margin-bottom: 5px;
            a {
                text-decoration: none;
                opacity: 0.65;
                &:visited {
                    color: black;
                }
            }
        }
    }

    @media (max-width: 930px), (orientation: portrait) {
        #root {
            padding: 0;
        }
    }
}
